body::-webkit-scrollbar {
  display: none;
}
.container{
    padding: 0;
    box-sizing: border-box;
}

.header{
    color: aliceblue;
    margin:auto;
}
hr{
    color: aliceblue;
}

h5{
    text-decoration: underline;
}


.resumeCont{
    margin-top: 10%;
    margin-bottom: 1%;
    width: 100%;
    border-radius: 5px;
    background-color: #212529;
    padding: 10px;
}

.resumeBody{
    color: aliceblue;
    margin-left: 5%;
}

.skOption{
    margin-left: 7%;
}

.custom {
    width: 150px !important;
    padding: 10px;
    margin: 5px;
}
.customDesc {
    margin: 2%;
    font-size: x-small;
    display: flex;
    justify-content: center;
    align-items: center;
}

.techDesc{
    margin: auto;
}

Button {
    padding: 2px;
}

.jobLength {
    font-size: x-small;
    font-style: italic;
    color: orange;
}

.eduCont{
    padding-bottom: 50px;
}

.downloadPdf{
    position: fixed;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    margin-bottom: 2%;
}

.jobDesc{
    font-size: small;
    opacity: 0.7;
    font-style: oblique;
}