@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');

body {
  background-image: url("./images/IMG_0628.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center center; 
}

body::-webkit-scrollbar {
  width: 0; 
  background: transparent;
}
