@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');
body{
  /* background-color: #282c34; */
  background-color: rgba(221,221,221,255);
}

body::-webkit-scrollbar {
  display: none;
}

.headerContainer{
  margin: auto;
  margin-top: 2%;
  width: 58%;
  background-color:  #212529;
  padding: 10px;
  border-radius: 15px;
  color: aliceblue;
  
}

.header{
  margin-bottom: 10%;
}

.cursor{
  color: orange;
}

.cursor::after {
  color: aliceblue;
  content: '_';
  animation: cursor 1.1s infinite step-start;
}
@keyframes cursor {
  50% {
    opacity: 0;
  }
}

.links{
  color: aliceblue;
}

a:hover{
  color: orange;
}
a{
  color: aliceblue;
}

.toolkit{
  color: aliceblue;
}


