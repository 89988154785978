.info {
    color: aliceblue;
}

span {
    color: orange;
}
body::-webkit-scrollbar {
    display: none;
}

.links:hover{
    color: orange;
}

.spacer{
    color: aliceblue;
    margin-top: 40px;
}
.p{
    font-size: 1.4rem;
}