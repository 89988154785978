@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

body{
    font-family: 'Bebas Neue', cursive;
  font-family: 'Comfortaa', cursive;
  font-family: 'Inconsolata', monospace;
  font-family: 'Lato', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Roboto Mono', monospace
}
.navbar-brand{
  margin-left: 1%;
}
.update {
  font-size: x-small;
  font-style: italic;
  color: orange;
  float: right;
}
.navbar-toggler{
  margin-right: 2%;
}

.navbar-nav{
  margin-right: 2%;
}