body::-webkit-scrollbar {
    display: none;
  }

.projectsCont{
    margin-top: 8%;
    width: 100%;
    border-radius: 5px;
    background-color: #212529;
    padding: 10px;
    color: aliceblue;
}

iframe{
    border-radius: 15px;
}

h1{
    color: aliceblue;
}
.ddivision {
    width: 100%;  /* Ensure it takes full width of the container */
    max-width: 450px;  /* Limit the maximum width to 450px */
    height: auto;  /* Adjust height to maintain aspect ratio */
    display: block;  /* Remove any inline display issues */
    margin: 0 auto;  /* Center the image horizontally */
}

@media (max-width: 768px) {
    .ddivision {
        max-width: 100%;  /* Ensure it doesn’t exceed the container width on mobile */
        height: auto;  /* Maintain aspect ratio on smaller screens */
    }
}


.prj{
    padding: 35px;
    justify-content: center;
}

.prjDesc{
    font-size: small;
    font-style: italic;
}
.prjTech{
    font-size: small;
    font-style: italic;
}

.prjBtn{
    width: 175px;
    margin: 5px;
    justify-content: center;
}

.prjm {
    margin-top: 20px;
}

.turkModel {
    margin-bottom: 40px;
}